// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".public-ui-Page-style__wrapper--aBoI0{padding-top:15rem}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "public-ui-Page-style__wrapper--aBoI0"
};
module.exports = exports;
