// @flow
import * as React from 'react'
import qs from 'qs'
import cx from 'classnames'

import api from 'shared/api'
import DateTime from 'shared/ui/DateTime'
import Paginated from 'shared/ui/List/Paginated'
import { useLocation } from 'react-router-dom'

import type { Event as TEvent } from 'shared/entities/Event'
import type { Errors } from 'shared/api'
import type { PaginatorTypeOverride } from 'shared/ui/Table'

import css from './styles.scss'

type Props = {
	organiser?: string,
	location?: string,
	performer?: string,
}

const Events = ({ organiser, location, performer }: Props) => {
	const l = useLocation()
	const [events, setEvents] = React.useState<?PaginatorTypeOverride<TEvent>>(
		null,
	)
	const [errors, setErrors] = React.useState<?Errors>(null)
	const [loading, setLoading] = React.useState<boolean>(true)

	const { search } = qs.parse(l.search, {
		ignoreQueryPrefix: true,
	})

	const getPath = React.useCallback(
		(): string => {
			if (location) {
				return `/locations/${location}/events`
			}

			if (organiser) {
				return `${organiser}/events`
			}

			if (performer) {
				return `/bandspage/${performer}/events`
			}

			return 'events'
		},
		[organiser, location, performer],
	)

	const fetchEvents = React.useCallback(
		async (pageNumber: number = 1) => {
			const response = await api({
				path: getPath(),
				method: 'GET',
				prefix: '/api/v1/',
				query: {
					search: typeof search === 'string' ? search : '',
					page: pageNumber,
				},
			})
			setLoading(false)
			return response
		},
		[search, getPath],
	)

	React.useEffect(
		() => {
			const handleFetchEvents = async () => {
				const response = await fetchEvents()
				if (response.ok) {
					setEvents({
						...response.json,
						data: Object.keys(response.json.data).map(
							(key) => response.json.data[key],
						),
					})
				} else {
					setErrors(response.json)
				}
			}
			handleFetchEvents()
		},
		[fetchEvents, search],
	)

	if (loading) {
		return (
			<div className={cx(css.wrapper, css.justText)}>
				<h2 className={css.error}>Loading</h2>
			</div>
		)
	}

	if (errors) {
		return (
			<div className={cx(css.wrapper, css.justText)}>
				<h2 className={css.error}>
					{`Somehting went wrong while fetching events`}
				</h2>
			</div>
		)
	}

	if (!events || events.data.length === 0) {
		return (
			<div className={cx(css.wrapper, css.justText)}>
				<h2 className={css.error}>No events found</h2>
			</div>
		)
	}

	if (events && events.total === 1) {
		window.location = events.data[0].endpoint
		return <div>Redirecting...</div>
	}

	const loadMoreEvents = async () => {
		const response = await fetchEvents(events.current_page + 1)
		if (response.ok) {
			setEvents((prevEvents) => {
				if (!prevEvents) {
					return response.json
				}

				const newEvents = Object.keys(response.json.data).map(
					(key) => response.json.data[key],
				)

				return {
					...response.json,
					data: prevEvents.data.concat(newEvents),
				}
			})
		} else {
			setErrors(response.json.errors)
		}
	}

	return (
		<Paginated
			itemClassName={css.item}
			listClassName={css.list}
			items={events}
			paginatorType="APPENDS"
			handleNext={loadMoreEvents}
			wrapperClassName={css.paginator}
			buttonsClassName={css.paginatorButton}
			buttonsDisabledClassName={css.pagnatorButtonsDisable}
			pageCountClassName={css.paginatorPageCount}
		>
			{(event) => (
				<>
					{event.start_date ? (
						<aside className={css.sideDate}>
							<DateTime date={event.start_date} format="Do, MMM" />
						</aside>
					) : (
						<aside className={css.noSideDate} />
					)}
					<a className={css.gotoButton} href={event.endpoint}>
						<div className={css.imageHolder}>
							<img
								className={css.image}
								src={event.bg_image_path}
								alt="Event logo"
							/>
						</div>
						<aside className={css.description}>
							<aside>
								<h1 className={css.title}>{event.title}</h1>
								<aside className={css.subheading}>
									{event.start_date && (
										<p>
											{`Start: `}
											<DateTime
												date={event.start_date}
												format="ddd, MMM D, YYYY h:mm a"
											/>
										</p>
									)}
									{`Location: `}
									{event.home_location}
								</aside>
								<div className={css.fakeButton}>More Info</div>
							</aside>
						</aside>
					</a>
				</>
			)}
		</Paginated>
	)
}

export default Events
