// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".public-pages-About-style__wrapper--33dRJ{background:#fff;color:#000;width:1140px;padding:10px 15px 15px 15px;margin:0 auto}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "public-pages-About-style__wrapper--33dRJ"
};
module.exports = exports;
