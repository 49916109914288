// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch } from 'react-router-dom'
import { AppContainer } from 'react-hot-loader'
import Navbar from 'public/Navbar'
import { Route } from 'react-router'
import ResendMyTickets from 'public/pages/ResendMyTickets'
import Subscribe from 'public/pages/Subscribe'
import About from 'public/pages/About'
import LocationsPage from 'public/pages/Locations'
import LocationEventsPage from 'public/pages/LocationLandingPage'
import LandingPage from 'public/pages/LandingPage'
import RecurringEventsPage from 'public/pages/RecurringEventsPage'
import OrganiserLandingPage from 'public/pages/OrganiserLandingPage'
import PerformersPage from 'public/pages/Performers'
import PerformerEventsPage from 'public/pages/PerformerLandingPage'

const Root = () => {
	return (
		<>
			<BrowserRouter>
				<Navbar />
				<Switch>
					<Route
						exact
						path="/resend-my-tickets"
						component={ResendMyTickets}
						key={1}
					/>
					<Route exact path="/subscribe" component={Subscribe} />
					<Route exact path="/about" component={About} />
					<Route exact path="/locations" component={LocationsPage} />
					<Route exact path="/bandspage" component={PerformersPage} />
					<Route
						exact
						path="/bandspage/:slug/events"
						component={PerformerEventsPage}
					/>
					<Route
						exact
						path="/locations/:slug/events"
						component={LocationEventsPage}
					/>
					<Route exact path="/" component={LandingPage} />
					<Route
						exact
						path="/:organiserId/events/:eventId/recurring"
						component={RecurringEventsPage}
					/>
					<Route exact path="/:organiser" component={OrganiserLandingPage} />
				</Switch>
			</BrowserRouter>
		</>
	)
}

const render = (Component) => {
	const node = document.querySelector('#react-public')
	if (!node) {
		throw new Error('React mount node missing')
	}

	ReactDOM.render(
		<AppContainer>
			<Component />
		</AppContainer>,
		node,
	)
}

render(Root)

// $FlowFixMe
if (module.hot) {
	// $FlowFixMe - No definition for module.hot
	module.hot.accept('./index', () => {
		render(Root)
	})
}
