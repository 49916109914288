// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".public-pages-EmailRequests-style__wrapper--3KMIg{background-color:#fff;color:#000;width:1140px;margin:0 auto;padding:5px 15px 15px 15px;margin-bottom:50vh}@media (max-width: 1200px){.public-pages-EmailRequests-style__wrapper--3KMIg{width:940px}}@media (max-width: 990px){.public-pages-EmailRequests-style__wrapper--3KMIg{width:720px}}@media (max-width: 768px){.public-pages-EmailRequests-style__wrapper--3KMIg{width:90%}}.public-pages-EmailRequests-style__title--155ir{text-align:center}.public-pages-EmailRequests-style__errors--9FDtb{border-color:red}.public-pages-EmailRequests-style__label--2eR7R{font-size:20pt}.public-pages-EmailRequests-style__labelWrapper--Kv4VD{width:100%}.public-pages-EmailRequests-style__input--5Y1IG{font-size:16px;padding:10px 5px;border:1px solid #e0e0e0;width:inherit}.public-pages-EmailRequests-style__button--24Drc{margin:0 auto;border:none;background:#fa7f1c;color:#fff;padding:10px 16px;text-decoration:to-upper-case;display:flex}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "public-pages-EmailRequests-style__wrapper--3KMIg",
	"title": "public-pages-EmailRequests-style__title--155ir",
	"errors": "public-pages-EmailRequests-style__errors--9FDtb",
	"label": "public-pages-EmailRequests-style__label--2eR7R",
	"labelWrapper": "public-pages-EmailRequests-style__labelWrapper--Kv4VD",
	"input": "public-pages-EmailRequests-style__input--5Y1IG",
	"button": "public-pages-EmailRequests-style__button--24Drc"
};
module.exports = exports;
