// @flow
import * as React from 'react'
import Page from 'public/ui/Page'
import api from 'shared/api'
import Paginated from 'shared/ui/List/Paginated'
import type { PaginatorTypeOverride } from 'shared/ui/Table'
import type { Event as TEvent } from 'shared/entities/Event'
import type { Errors } from 'shared/api'
import css from '../style.scss'
import Search from './Search'

const { useState, useEffect, useCallback } = React

const Performers = () => {
	const [slug, setSlug] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)
	const [performers, setPerformers] = useState<?PaginatorTypeOverride<TEvent>>(
		null,
	)
	const [errors, setErrors] = useState<?Errors>(null)

	const onSearch = async (value: string): Promise<void> => {
		setSlug(value)
	}

	const fetchPerformers = useCallback(
		async (page: number = 1) => {
			const response = await api({
				path: '/bandspage',
				method: 'GET',
				prefix: '/api/v1/',
				query: {
					search: slug,
					page,
					forOrganiser: '1',
				},
			})

			setLoading(false)

			return response
		},
		[setLoading, slug],
	)

	useEffect(
		() => {
			const handleFetch = async () => {
				const response = await fetchPerformers()

				if (response.ok) {
					setPerformers({
						...response.json,
						data: Object.keys(response.json.data).map(
							(key) => response.json.data[key],
						),
					})
				} else {
					setErrors(response.json)
				}
			}

			handleFetch()
		},
		[fetchPerformers, slug],
	)

	if (loading) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>Loading...</h1>
				</div>
			</Page>
		)
	}

	if (errors) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>
						{`Something went wrong while fetching the events`}
					</h1>
				</div>
			</Page>
		)
	}

	if (!performers || performers.data.length === 0) {
		return (
			<Page>
				<div className={css.wrapper}>
					<h1 className={css.header}>No performers found</h1>
				</div>
			</Page>
		)
	}

	const loadMorePerformers = async () => {
		const response = await fetchPerformers(performers.current_page + 1)

		if (response.ok) {
			setPerformers((prevPerformers) => {
				if (!prevPerformers) {
					return response.json
				}

				const newPerformers = Object.keys(response.json.data).map(
					(key) => response.json.data[key],
				)

				return {
					...response.json,
					data: prevPerformers.data.concat(newPerformers),
				}
			})
		} else {
			setErrors(response.json.errors)
		}
	}

	return (
		<Page>
			<div className={css.wrapper}>
				<h1 className={css.header}>Performers</h1>
				<Search onSubmit={onSearch} />
				<Paginated
					itemClassName={css.item}
					listClassName={css.list}
					items={performers}
					paginatorType="APPENDS"
					handleNext={loadMorePerformers}
					wrapperClassName={css.paginator}
					buttonsClassName={css.paginatorButton}
					buttonsDisabledClassName={css.paginatorButtonsDisable}
					pageCountClassName={css.paginatorPageCount}
				>
					{(performer) => (
						<>
							<a
								className={css.gotoButton}
								href={`/bandspage/${performer.slug}`}
							>
								<aside className={css.description}>
									<aside>
										<h1 className={css.title}>{performer.name}</h1>
										<div className={css.subheading}>
											{`Description: `}
											{performer.description}
										</div>
										<div className={css.fakeButton}>View Performer</div>
									</aside>
								</aside>
							</a>
						</>
					)}
				</Paginated>
			</div>
		</Page>
	)
}

export default Performers
