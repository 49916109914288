// @flow
// eslint-ignore react/destructuring-assignment
import * as React from 'react'
import cx from 'classnames'
import type { PaginatorTypeOverride } from 'shared/ui/Table'
import List from './index'

import type { ListItem, Props as ListProps } from './index'

export const PAGINATOR_TYPE = {
	appends: 'APPENDS',
	pages: 'PAGES',
}

type AppendsButtonSet = {|
	wrapperClassName?: string,
	buttonsClassName?: string,
	pageCountClassName?: string,
	buttonsDisabledClassName?: string,
	handleNext: () => void | Promise<void>,
	items: PaginatorTypeOverride<any>,
|}

type PageButtonSetProps = {|
	...AppendsButtonSet,
	handlePrev: () => void | Promise<void>,
|}

type ButtonsProps =
	| {|
			paginatorType: 'APPENDS',
			...AppendsButtonSet,
	  |}
	| {|
			paginatorType: 'PAGES',
			...PageButtonSetProps,
	  |}

type Props<P> = {|
	...ListProps<P>,
	items: PaginatorTypeOverride<P>,
	...ButtonsProps,
|}

const PageButtonSet = ({
	wrapperClassName,
	buttonsClassName,
	buttonsDisabledClassName,
	items,
	handleNext,
	handlePrev,
}: PageButtonSetProps) => (
	<div className={wrapperClassName}>
		<button
			type="button"
			onClick={handlePrev}
			disabled={items.current_page === 1}
			className={
				(buttonsClassName,
				items.current_page === 1 ? buttonsDisabledClassName : '')
			}
		>
			{`Previous`}
		</button>
		<span>{`${items.current_page}/${items.last_page}`}</span>
		<button
			type="button"
			onClick={handleNext}
			disabled={items.current_page === items.last_page}
			className={
				(buttonsClassName,
				items.current_page === items.last_page ? buttonsDisabledClassName : '')
			}
		>
			{`Next`}
		</button>
	</div>
)

const AppendButtonSet = ({
	wrapperClassName,
	buttonsClassName,
	buttonsDisabledClassName,
	pageCountClassName,
	items,
	handleNext,
}: AppendsButtonSet) => (
	<div className={wrapperClassName}>
		<p className={pageCountClassName}>
			{`${
				items.per_page * items.current_page > items.total
					? items.total
					: items.per_page * items.current_page
			}/${items.total}`}
		</p>
		<button
			type="button"
			className={cx(
				buttonsClassName,
				items.current_page === items.last_page ? buttonsDisabledClassName : '',
			)}
			onClick={handleNext}
			disabled={items.current_page === items.last_page}
		>
			{`Load more`}
		</button>
	</div>
)

const Paginated = <P: ListItem>({
	items,
	listClassName,
	itemClassName,
	children,
	handleNext,
	pageCountClassName,
	buttonsClassName,
	buttonsDisabledClassName,
	wrapperClassName,
	...rest
}: Props<P>) => {
	const Buttons =
		rest.paginatorType === PAGINATOR_TYPE.pages ? (
			<PageButtonSet
				items={items}
				handleNext={handleNext}
				pageCountClassName={pageCountClassName}
				wrapperClassName={wrapperClassName}
				handlePrev={rest.handlePrev}
				buttonsDisabledClassName={buttonsDisabledClassName}
				buttonsClassName={buttonsClassName}
			/>
		) : (
			<AppendButtonSet
				items={items}
				handleNext={handleNext}
				pageCountClassName={pageCountClassName}
				wrapperClassName={wrapperClassName}
				buttonsDisabledClassName={buttonsDisabledClassName}
				buttonsClassName={buttonsClassName}
			/>
		)

	return (
		<div className={listClassName}>
			<List
				items={items.data}
				listClassName={listClassName}
				itemClassName={itemClassName}
			>
				{children}
			</List>
			{Buttons}
		</div>
	)
}

export default Paginated
