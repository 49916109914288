// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".public-Navbar-Submenu-styles__hidden--39c6D{visibility:hidden}.public-Navbar-Submenu-styles__navItem--3p2sw{color:#fff;transition:color 0s}.public-Navbar-Submenu-styles__navItem--3p2sw:hover{color:#fa7f1c}.public-Navbar-Submenu-styles__submenuButton--1d0ke{margin-bottom:0;padding-bottom:10px}.public-Navbar-Submenu-styles__submenu--1F1dA{position:absolute;margin:0;padding:0;list-style:none;background-color:rgba(25,34,47,0.9);color:#fff;min-width:200px;border-bottom:#fa7f1c 5px solid;flex-basis:auto;flex-direction:row;flex-grow:0;overflow:hidden}.public-Navbar-Submenu-styles__submenu--1F1dA>li:hover{background-color:rgba(0,0,0,0.03)}.public-Navbar-Submenu-styles__submenu--1F1dA>li{padding:0.21429rem 1.07143rem}.public-Navbar-Submenu-styles__submenu--1F1dA>li:first-child{border-top-left-radius:calc(.25rem - 1px);border-top-right-radius:calc(.25rem - 1px)}.public-Navbar-Submenu-styles__inlineSubmenu--1O3K1{padding-bottom:0}.public-Navbar-Submenu-styles__inlineSubmenuList--34ySF{position:initial;max-height:600px;transition:max-height 1s ease-in}.public-Navbar-Submenu-styles__inlineSubmenuList--34ySF>*{max-height:inherit}.public-Navbar-Submenu-styles__hideDropdown--2aplA{max-height:0;border-bottom:none}.public-Navbar-Submenu-styles__subNavItem--16iwu{visibility:inherit}.public-Navbar-Submenu-styles__subNavItem--16iwu{color:inherit;transition:color .2s}.public-Navbar-Submenu-styles__subNavItem--16iwu:hover{color:#fa7f1c}\n", ""]);
// Exports
exports.locals = {
	"hidden": "public-Navbar-Submenu-styles__hidden--39c6D",
	"navItem": "public-Navbar-Submenu-styles__navItem--3p2sw",
	"submenuButton": "public-Navbar-Submenu-styles__submenuButton--1d0ke",
	"submenu": "public-Navbar-Submenu-styles__submenu--1F1dA",
	"inlineSubmenu": "public-Navbar-Submenu-styles__inlineSubmenu--1O3K1",
	"inlineSubmenuList": "public-Navbar-Submenu-styles__inlineSubmenuList--34ySF",
	"hideDropdown": "public-Navbar-Submenu-styles__hideDropdown--2aplA",
	"subNavItem": "public-Navbar-Submenu-styles__subNavItem--16iwu"
};
module.exports = exports;
