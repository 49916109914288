// @flow
import * as React from 'react'
import Events from 'public/Events'
import { useParams } from 'react-router'
import api from 'shared/api'
import styles from '../Title/styles.scss'

type Location = {
	id: number,
	slug: string,
	name: string,
	venueFullName: ?string,
}

const { useEffect, useState } = React

const LandingPage = () => {
	const { slug } = useParams()

	const [location, setLocation] = useState<?Location>()

	useEffect(
		() => {
			if (!slug) {
				return
			}

			const fetchLocation = async () => {
				const response = await api({
					path: `locations/${slug}`,
					method: 'GET',
					prefix: '/api/v1/',
				})

				if (response.ok) {
					const { address, id, venue_name_full } = response.json

					setLocation({
						id,
						slug,
						name: address,
						venueFullName: venue_name_full,
					})
				} else {
					setLocation({
						id: 0,
						name: 'Location not found',
						slug,
						venueFullName: null,
					})
				}
			}
			fetchLocation()
		},
		[slug],
	)

	if (!location || !slug) {
		return (
			<section className={styles.title}>
				<h1>Loading...</h1>
			</section>
		)
	}

	return (
		<>
			<h1 className={styles.title}>{location.venueFullName}</h1>
			<Events location={slug} />
		</>
	)
}

export default LandingPage
