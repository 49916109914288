// @flow
import React, { useEffect, useState } from 'react'
import Events from 'public/Events'
import { useLocation } from 'react-router'
import api from 'shared/api'
import styles from '../Title/styles.scss'

type Organiser = {
	id: number,
	slug: string,
	name: string,
}

const LandingPage = () => {
	const location = useLocation()

	const [organiser, setOrganiser] = useState<?Organiser>()

	const organiserSlug = location.pathname.replace(/\//g, '')

	useEffect(
		() => {
			const fetchOrganiser = async () => {
				const response = await api({
					path: 'organisers',
					method: 'GET',
					prefix: '/api/v1/',
					query: {
						slug: organiserSlug,
					},
				})

				if (response.ok) {
					setOrganiser(response.json)
				} else {
					setOrganiser({
						id: 0,
						name: 'Organiser not found',
						slug: organiserSlug,
					})
				}
			}
			fetchOrganiser()
		},
		[organiserSlug],
	)

	if (!organiser) {
		return (
			<section className={styles.title}>
				<h1>Loading...</h1>
			</section>
		)
	}

	return (
		<>
			<div className={styles.title}>
				<h1>{organiser.name}</h1>
			</div>
			<Events organiser={organiserSlug} />
		</>
	)
}

export default LandingPage
