// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".public-Search-styles__wrapper--3lWBP{display:flex}.public-Search-styles__input--39K0B{padding:11.2px;border:none;font-size:12px;font-weight:400;width:inherit}.public-Search-styles__input--39K0B::placeholder{color:#fa7f1c}.public-Search-styles__button--35DXf{padding:0.5rem 1.4rem;background-color:inherit;display:inline-block;border:none;min-width:30px;min-height:100%}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "public-Search-styles__wrapper--3lWBP",
	"input": "public-Search-styles__input--39K0B",
	"button": "public-Search-styles__button--35DXf"
};
module.exports = exports;
